import axios from "axios";
import store from "@/store";
import router from "@/router";
import Cookies from "js-cookie";

// Create a new axios instance
const apiInstance = axios.create();

// Helper function to set common headers
const setRequestHeaders = (config) => {
  const token = store.state.token;
  const sessionId = Cookies.get("session_id");

  return {
    ...config.headers,
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: `Bearer ${token}`,
    Cookie: sessionId,
  };
};

// Helper function to handle response cookies
const handleResponseCookies = (res) => {
  if (res.headers && res.headers["set-cookie"]) {
    Cookies.set("session_id", res.headers["set-cookie"], { expires: 1 });
  }
};

// Helper function to handle token refresh
const refreshAccessToken = async (err) => {
  if (!store.state.refreshToken) {
    await store.dispatch("removeSession");
    router.push("/");
    return Promise.reject(
      new Error("No refresh token available, logging out.")
    );
  }

  try {
    const newAccessToken = await store.dispatch("refreshToken");
    err.config.headers["Authorization"] = `Bearer ${newAccessToken}`;
    return apiInstance.request(err.config);
  } catch (refreshError) {
    console.error("Unable to refresh token:", refreshError);
    return Promise.reject(refreshError);
  }
};

// Request interceptor
apiInstance.interceptors.request.use(async (config) => {
  // Check for localStorage activeSession
  const token = store.state.token;
  const activeSession = localStorage.getItem("activeSession");

  if (!token && (!activeSession || activeSession === "false")) {
    store.commit("setToken", "");
    store.commit("setRoleAction", null);
    router.go(); // Trigger refresh of the current page
    // return Promise.reject(new Error("Session expired or inactive."));
  }

  config.headers = setRequestHeaders(config);
  return config;
});

// Response interceptor
apiInstance.interceptors.response.use(
  (res) => {
    handleResponseCookies(res);
    return res;
  },
  async (err) => {
    const { response } = err;
    // console.log('response',response.request?.responseURL)
    // console.log('env',process.env.VUE_APP_API_URL)
    const request_path = response.request?.responseURL;
    const user_path = `${process.env.VUE_APP_API_URL}/user`;

    if (!response) {
      return Promise.reject(err);
    }

    switch (response.status) {
      case 409:
        if (store.state.count_double_login === 0) {
          store.commit("setCountDoubleLogin", 1);
          // router.go(); // Trigger refresh of the current page
        }
        break;

      case 401:
        return refreshAccessToken(err);

      // default:
      //   store.commit("setToken", "");
      //   store.commit("setRoleAction", null);
      //   router.go(); // Trigger refresh of the current page
      //   break;
    }
    if (
      err.status === 409 ||
      (err.status === 401 && request_path !== user_path)
    ) {
      return Promise.reject(null);
    }
    return Promise.reject(err);
  }
);

export const httpClient = apiInstance;
