<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      v-if="size === 'sm'"
    >
      <path
        d="M11.5 15H13.5V17H11.5V15ZM11.5 7H13.5V13H11.5V7ZM19.5 3H15.32C14.9 1.84 13.8 1 12.5 1C11.2 1 10.1 1.84 9.68 3H5.5C5.36 3 5.23 3.01 5.1 3.04C4.71 3.12 4.36 3.32 4.09 3.59C3.91 3.77 3.76 3.99 3.66 4.23C3.56 4.46 3.5 4.72 3.5 5V19C3.5 19.27 3.56 19.54 3.66 19.78C3.76 20.02 3.91 20.23 4.09 20.42C4.36 20.69 4.71 20.89 5.1 20.97C5.23 20.99 5.36 21 5.5 21H19.5C20.6 21 21.5 20.1 21.5 19V5C21.5 3.9 20.6 3 19.5 3ZM12.5 2.75C12.91 2.75 13.25 3.09 13.25 3.5C13.25 3.91 12.91 4.25 12.5 4.25C12.09 4.25 11.75 3.91 11.75 3.5C11.75 3.09 12.09 2.75 12.5 2.75ZM19.5 19H5.5V5H19.5V19Z"
        :fill="color || '#F68809'"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      viewBox="0 0 32 32"
      fill="none"
      v-else
    >
      <path
        d="M14.6667 20.0002H17.3333V22.6668H14.6667V20.0002ZM14.6667 9.3335H17.3333V17.3335H14.6667V9.3335ZM25.3333 4.00016H19.76C19.2 2.4535 17.7333 1.3335 16 1.3335C14.2667 1.3335 12.8 2.4535 12.24 4.00016H6.66667C6.48 4.00016 6.30667 4.0135 6.13333 4.0535C5.61333 4.16016 5.14667 4.42683 4.78667 4.78683C4.54667 5.02683 4.34667 5.32016 4.21333 5.64016C4.08 5.94683 4 6.2935 4 6.66683V25.3335C4 25.6935 4.08 26.0535 4.21333 26.3735C4.34667 26.6935 4.54667 26.9735 4.78667 27.2268C5.14667 27.5868 5.61333 27.8535 6.13333 27.9602C6.30667 27.9868 6.48 28.0002 6.66667 28.0002H25.3333C26.8 28.0002 28 26.8002 28 25.3335V6.66683C28 5.20016 26.8 4.00016 25.3333 4.00016ZM16 3.66683C16.5467 3.66683 17 4.12016 17 4.66683C17 5.2135 16.5467 5.66683 16 5.66683C15.4533 5.66683 15 5.2135 15 4.66683C15 4.12016 15.4533 3.66683 16 3.66683ZM25.3333 25.3335H6.66667V6.66683H25.3333V25.3335Z"
        :fill="color || '#F68809'"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      // Set to true if this prop is required
    },
    size: {},
    width: {
      default: "32",
    },
    height: {
      default: "32",
    },
  },
};
</script>
