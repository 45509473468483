<template>
  <div>
    <div v-if="size === 'lg'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        v-if="direction === 'up'"
      >
        <path
          d="M9.29208 3.25165L16.1716 10.7229C16.6095 11.1982 16.6095 11.9687 16.1716 12.4438C15.7342 12.9188 15.0246 12.9188 14.5872 12.4437L8.49989 5.83285L2.41276 12.4436C1.97513 12.9186 1.26568 12.9186 0.828226 12.4436C0.390591 11.9685 0.390592 11.198 0.828227 10.7227L7.70787 3.25146C7.9267 3.01392 8.21321 2.89529 8.49985 2.89529C8.78664 2.89529 9.07336 3.01415 9.29208 3.25165Z"
          :fill="color || '#212121'"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        v-else-if="direction === 'down'"
      >
        <path
          d="M7.70792 12.7484L0.828385 5.2771C0.390537 4.80184 0.390537 4.03128 0.828385 3.55625C1.26584 3.08118 1.97536 3.08118 2.41278 3.55625L8.50011 10.1672L14.5872 3.55645C15.0249 3.08138 15.7343 3.08138 16.1718 3.55645C16.6094 4.03152 16.6094 4.80203 16.1718 5.27729L9.29213 12.7485C9.0733 12.9861 8.78679 13.1047 8.50015 13.1047C8.21336 13.1047 7.92664 12.9858 7.70792 12.7484Z"
          fill="white"
        />
      </svg>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      v-else
    >
      <path
        d="M12.1968 6.47003L11.2568 5.53003L8.19678 8.58336L5.13678 5.53003L4.19678 6.47003L8.19678 10.47L12.1968 6.47003Z"
        :fill="color || '#212121'"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: ["color", "size", "direction"],
};
</script>
