import moment from "moment";
import store from "../store";
let lang = store.state.selectLang.lang;
export default {
  methods: {
    formatDateTh(date) {
      moment.locale("th");
      // let dateTh  = moment().add(543, date).format('LL')
      return moment(date).format("LL");
    },
    
    convertToTreeData(data) {
      const treeData = [];
      data.forEach((company) => {
        // console.log('company.branches',company.branches.length == 0)
        const companyData = {
          title: company.name_th,
          value: `company-${company.id}`,
          key: company.id.toString(),
          level: 1,
          children: [],
          // disabled : company.branches && company.branches.length == 0 ? true : false
        };

        if (company.branches && company.branches.length > 0) {
          company.branches.forEach((branch) => {
            const branchData = {
              title: branch.name,
              value: `branch-${branch.id}`,
              key: `${company.id.toString()}-${branch.id.toString()}`,
              level: 2,
              children: [],
              // disabled : branch.buildings && branch.buildings.length == 0 ? true : false
            };

            if (branch.buildings && branch.buildings.length > 0) {
              branch.buildings.forEach((building) => {
                const buildingData = {
                  title: building.name,
                  value: `building-${building.id}`,
                  key: `${company.id.toString()}-${branch.id.toString()}-${building.id.toString()}`,
                  level: 3,
                };
                branchData.children.push(buildingData);
              });
            }

            // เพิ่มข้อมูลของสาขาลงใน children ของบริษัท
            companyData.children.push(branchData);
          });
        }

        // เพิ่มโครงสร้างของบริษัทลงใน Tree Data
        treeData.push(companyData);
      });
      return treeData;
    },

    findNode(tree, value) {
      // ค้นหา Node ใน tree โดยใช้ value
      for (const node of tree) {
        if (node.value === value) {
          return node;
        }
        if (node.children) {
          const foundNode = this.findNode(node.children, value);
          if (foundNode) {
            return foundNode;
          }
        }
      }
      return null;
    },

    getAllParents(node) {
      const parents = [];
      let currentNode = node;
      parents.push(currentNode.value);
      return parents;
    },
    findNodeParent2(tree, targetNode) {
      if (targetNode.level == 1) {
        for (let data of tree) {
          if (data.children && data.children.includes(targetNode)) {
            return data;
          }
        }
      }
      return null;
      // let find_target = targetNode.split("-");
      // console.log('find_target',find_target)
    },

    findNodeParent(tree, targetNode) {
      // ค้นหา Node Parent ของ targetNode
      // console.log('targetNode',targetNode)
      for (const node of tree) {
        // console.log('node',node.children.includes(targetNode))
        if (node.children && node.children.includes(targetNode)) {
          return node;
        }
        if (node.children) {
          const foundNode = this.findNodeParent(node.children, targetNode);
          if (foundNode) {
            return foundNode;
          }
        }
      }
      return null;
    },

    increaseDate(date, totalDays) {
      let dateTime = new Date(date).getTime(); //convert string to date time
      let timeStampTotalDay = 3600 * 1000 * 24 * totalDays; //timestamp per day * total increase date

      let dateTimeIncrease = dateTime + timeStampTotalDay;
      moment.locale("th");
      return this.dateTimeTh(dateTimeIncrease);
    },

    //จะ return utc
    increaseUTCDate(dateTime, totalIncrease) {
      const lastDate = new Date(dateTime);

      const theDayAfterLastDate = lastDate; //ประกาศให้วันตั้งต้นคือวันที่ส่งเข้ามาเพื่อเอาไปบวกหนึ่ง

      //Add 1 Day จะได้ค่า 2022-09-25T11:02:49+07:00
      theDayAfterLastDate.setDate(lastDate.getDate() + totalIncrease);

      return theDayAfterLastDate;
    },

    monthTh(dateTime) {
      // dateTime ที่ส่งเข้ามาต้องเป็นวันที่
      if (dateTime) {
        let lang = store.state.selectLang.lang;
        let dateLang = lang == "th" ? "th-TH" : "en-ZA";
        let convertStringToDate = new Date(dateTime); //แปลงวันที่
        let month = convertStringToDate.toLocaleString(dateLang, {
          month: "long",
        });
        let year = convertStringToDate.getFullYear();
        let formattedDate = `${month} ${year}`;
        // const dateTimeTh = convertStringToDate.toLocaleDateString(dateLang, {
        //   year: "numeric",
        //   month: "long",
        // });

        return formattedDate;
      }
    },

    yearTh(dateTime) {
      if (dateTime) {
        // let lang = store.state.selectLang.lang;
        // let dateLang = lang == "th" ? "th-TH" : "en-ZA";
        let convertStringToDate = new Date(dateTime); //แปลงวันที่
        let year = convertStringToDate.getFullYear();
        let formattedDate = `${year}`;
        // const dateTimeTh = convertStringToDate.toLocaleDateString(dateLang, {
        //   year: "numeric",
        // });

        return formattedDate;
      }
    },

    dateTimeTh(dateTime) {
      // datetime ที่ส่งเข้ามาต้องเป็นวันที่
      if (dateTime) {
        let lang = store.state.selectLang.lang;
        let dateLang = lang == "th" ? "th-TH" : "en-ZA";
        let convertStringToDate = new Date(dateTime); //แปลงวันที่
        let day = convertStringToDate.getDate();
        let month = convertStringToDate.toLocaleString(dateLang, {
          month: "long",
        });
        let year = convertStringToDate.getFullYear();
        let formattedDate = `${day} ${month} ${year}`;
        // const dateTimeTh = convertStringToDate.toLocaleDateString(dateLang, {
        //   year: "numeric",
        //   month: "long",
        //   day: "numeric",
        // });

        return formattedDate;
      }
    },
    dateTimeShort(dateTime) {
      // dateTime ที่ส่งเข้ามาต้องเป็นวันที่
      let lang = store.state.selectLang.lang;
      let dateLang = lang == "th" ? "th-TH" : "en-ZA";
      let convertStringToDate = new Date(dateTime); //แปลงวันที่
      const dateTimeTh = convertStringToDate.toLocaleDateString(dateLang, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      return dateTimeTh;
    },

    timeThai(dateTime) {
      let lang = store.state.selectLang.lang;
      let dateLang = lang == "th" ? "th-TH" : "en-ZA";
      let convertStringToDate = new Date(dateTime); //แปลงวันที่
      let time = convertStringToDate.toLocaleTimeString(dateLang, {
        hour: "numeric",
        minute: "numeric",
      });

      return time;
    },

    //ลบเวลา และ return เป็น iso datetime
    diffHour(dateTime, hours) {
      //dateTime ต้องเป็นวันที่มาแล้ว
      dateTime.getTime();
      let diff = dateTime - hours * 3600; //return unix time
      let convertUnixTime = new Date(diff);
      return convertUnixTime.toISOString();
    },

    twelveDaysAgo() {
      let start = Date.now() / 1000 - 3600 * 24 * 12;
      let convertUnixTime = new Date(start * 1000);
      convertUnixTime.toISOString();
      return moment(convertUnixTime).utc().format();
    },

    numberOnly(event) {
      if (!/\d/.test(event.key) && event.key != ".") {
        return event.preventDefault();
      }
    },
    phoneNumber(event) {
      if (!/^\d$/.test(event.key) || event.target.value.length >= 10) {
        event.preventDefault();
      }
    },
    checkMax100(event) {
      if (parseFloat(event.target.value) > 100) {
        return (event.target.value = "100");
      }
    },

    decimalFormat(number) {
      return Intl.NumberFormat("th-TH", {
        currency: "THB",
        minimumFractionDigits: 0,
      }).format(number);
    },

    UTCDateTime(dateTime) {
      return moment(dateTime).format("YYYY-MM-DD");
    },

    base64ToBlob(dataURI, image_type) {
      let img_type = image_type ? image_type : "image/jpg";
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: img_type });
    },

    checkOnlyImage(measure_file) {
      // console.log('measure_file',measure_file);
      if (measure_file && measure_file.file) {
        if (
          measure_file.file.file_url.includes("_blob") ||
          measure_file.file.file_url.includes(".png") ||
          measure_file.file.file_url.includes(".jpg") ||
          measure_file.file.file_url.includes(".jpeg")
        ) {
          return true;
        } else {
          return false;
        }
      }
    },

    gettimeNow() {
      let currentDate = new Date();
      let hour = ("0" + currentDate.getHours()).slice(-2);
      let minute = ("0" + currentDate.getMinutes()).slice(-2);
      // let hour = currentDate.getHours() < 10 ? '0' + currentDate.getHours() : currentDate.getHours()
      // let minute = currentDate.getMinutes() < 10 ? '0' + currentDate.getMinutes() : currentDate.getMinutes()
      let timeNow = hour + ":" + minute;
      // let timeNow = currentDate.getHours() + ":" + currentDate.getMinutes()
      return timeNow;
    },

    frequencyName(type_id) {
      let type_name = null;

      switch (type_id) {
        case 0:
          type_name = lang == "th" ? "กรอกแบบรายวัน" : "Daily fill out";
          break;
        case 1:
          type_name =
            lang == "th" ? "กรอกแบบรายเดือน" : "Fill out monthly form";
          break;
        case 2:
          type_name = lang == "th" ? "กรอกแบบรายปี" : "Fill out an annual form";
          break;
        case 3:
          type_name = lang == "th" ? "กรอกแบบรายครั้ง" : "Event Frequency";
          break;
      }

      return type_name;
    },

    inputTypeName(type_id) {
      let type_name = null;

      switch (type_id) {
        case 0:
          type_name =
            lang == "th" ? "แบบปริมาณการใช้งาน" : "Actual amount used";
          break;
        case 1:
          type_name = lang == "th" ? "แบบสะสม" : "Cumulative amount used";
          break;
      }

      return type_name;
    },

    roleUserId(role) {
      let role_id = 0;
      switch (role) {
        case "Super Admin":
          role_id = 1000;
          break;
        case "Admin":
          role_id = 100;
          break;
        case "Importer":
          role_id = 1;
          break;
        case "Viewer":
          role_id = 0;
          break;
      }
      return role_id;
    },

    successAlert(word) {
      this.$swal({
        icon: "success",
        text: word,
        showConfirmButton: false,
        timer: 1500,
      });
    },

    monthYearThai(dateTime) {
      // dateTime ที่ส่งเข้ามาต้องเป็นวันที่
      let lang = store.state.selectLang.lang;
      let dateLang = lang == "th" ? "th-TH" : "UTC";
      let convertStringToDate = new Date(dateTime); //แปลงวันที่
      const dateTimeTh = convertStringToDate.toLocaleDateString(dateLang, {
        year: "numeric",
        month: "long",
      });

      return dateTimeTh;
    },

    numberWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    numberFormatShort(value) {
      return Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        notation: "compact",
        compactDisplay: "short",
      }).format(value);
    },

    companyName(company) {
      if (lang == "th") {
        return company.name_th;
      } else if (lang == "en") {
        return company.name_en;
      }
    },

    evidenceName(evidence_id) {
      if (evidence_id == 1) {
        return this.$t("DD_FROM_ESTIMATION");
      } else if (evidence_id == 2) {
        return this.$t("DD_FROM_PROOF_OF_PAYMENT");
      } else if (evidence_id == 3) {
        return this.$t("DD_FROM_MEASUREMENT_LIST");
      } else {
        return "-";
      }
    },

    decimalNotRounded(number) {
      // let value = typeof (number) != 'string' ? number : Number(number);
      let value = null;
      if (typeof number != "string") {
        value = number;
      } else {
        if (this.isCommaFormat(number) == true) {
          value = Number(number.replace(/,/g, ""));
        } else {
          value = Number(number);
        }
      }
      if (value % 1 == 0) {
        return this.decimalFormat(value.toFixed(2));
      } else {
        return this.decimalFormat(
          Number(value.toString().match(/^\d+(?:\.\d{0,2})?/))
        );
      }
    },
    isCommaFormat(text) {
      // Regular expression to match comma-separated format
      var regex = /^\d{1,3}(,\d{3})*(\.\d+)?$/;

      // Test if the text matches the pattern
      return regex.test(text);
    },
    setFormatDateTime() {
      return {
        stringify: (date) => {
          if (date && date.start && date.end) {
            return `${this.monthTh(date.start)} - ${this.monthTh(date.end)}`;
          } else if (date) {
            return this.monthTh(date);
          } else {
            return null;
          }
        },
      };
    },
    setFormatMonth() {
      return {
        stringify: (date) => {
          if (date && date.start && date.end) {
            return `${this.monthTh(date.start)} - ${this.monthTh(date.end)}`;
          } else if (date) {
            return this.monthTh(date);
          } else {
            return null;
          }
        },
      };
    },
    setFormatYear() {
      return {
        stringify: (date) => {
          if (date && date.start && date.end) {
            return `${this.yearTh(date.start)} - ${this.yearTh(date.end)}`;
          } else if (date) {
            return this.yearTh(date);
          } else {
            return null;
          }
        },
      };
    },
    disableFutureMonth(date) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      // Extract year and month from the date to be checked
      const yearToCheck = date.getFullYear();
      const monthToCheck = date.getMonth();

      // Disable future months (months occurring after the current month and year)
      if (
        yearToCheck > currentYear ||
        (yearToCheck === currentYear && monthToCheck > currentMonth)
      ) {
        return true; // Disable the date if it's a future month
      }
    },
    disableFutureDate(date) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();

      // Extract year, month, and day from the date to be checked
      const yearToCheck = date.getFullYear();
      const monthToCheck = date.getMonth();
      const dayToCheck = date.getDate();

      // Disable future dates (dates occurring after the current date)
      if (
        yearToCheck > currentYear ||
        (yearToCheck === currentYear && monthToCheck > currentMonth) ||
        (yearToCheck === currentYear &&
          monthToCheck === currentMonth &&
          dayToCheck > currentDay)
      ) {
        return true; // Disable the date if it's in the future
      }

      return false; // Enable the date if it's not in the future
    },

    checkPermissionEditAudit(card, mode) {
      const auditor_type = this.$store.state.auditor_type;
      const data_permission = this.$store.state.permissions;
      if (
        !auditor_type &&
        card &&
        (card.status == 0 || card.status == 3) &&
        mode == "edit" &&
        data_permission &&
        data_permission.audit &&
        data_permission.audit.edit
      ) {
        return true;
      } else {
        return false;
      }
    },
    convertDateToLocaleDate(date) {
      return moment(date).format();
    },
    precision(value, p) {
      if (value === "") {
        return "";
      } else {
        const num = Number(value.replace(/,/g, ""));
        return +(Math.round(num + `e+${p}`) + `e-${p}`);
      }
    },
    switchStandard(type, scope, full_name) {
      // console.log("type", type, scope);
      if (type && scope) {
        if (full_name) {
          if (type === 2) {
            switch (scope) {
              case "1":
                return this.$t("TXT_ISO_CATE_1_FULL_NAME");
              case "2":
                return this.$t("TXT_ISO_CATE_2_FULL_NAME");
              case "3":
                return this.$t("TXT_ISO_CATE_3_6_FULL_NAME");
              case "3-6":
                return this.$t("TXT_ISO_CATE_3_6_FULL_NAME");
              case "4":
                return this.$t("TXT_ISO_CATE_4_FULL_NAME");
              case "5":
                return this.$t("TXT_ISO_CATE_5_FULL_NAME");
              case "6":
                return this.$t("TXT_ISO_CATE_6_FULL_NAME");
              case "อื่นๆ":
                return this.$t("TXT_ISO_SEPARATE_REPORT_FULL_NAME");
              default:
                break;
            }
          } else if (type === 1) {
            switch (scope) {
              case "1":
                return this.$t("TXT_SCOPE1_AUDITOR");
              case "2":
                return this.$t("TXT_SCOPE2_AUDITOR");
              case "3":
                return this.$t("TXT_SCOPE3_AUDITOR");
              case "อื่นๆ":
                return this.$t("TXT_SCOPE_ETC_AUDITOR");
              default:
                break;
            }
          }
        } else {
          if (type === 2) {
            switch (scope) {
              case "1":
                return this.$t("TXT_ISO_CATE_1");
              case "2":
                return this.$t("TXT_ISO_CATE_2");
              case "3":
                return this.$t("TXT_ISO_CATE_3");
              case "3-6":
                return this.$t("TXT_ISO_CATE_3_6");
              case "4":
                return this.$t("TXT_ISO_CATE_4");
              case "5":
                return this.$t("TXT_ISO_CATE_5");
              case "6":
                return this.$t("TXT_ISO_CATE_6");
              case "อื่นๆ":
                return this.$t("TXT_ISO_SEPARATE_REPORT");
              case "1 & 2":
                return this.$t("TXT_SUM_CATE1_2");
              case "1 & 2 & 3-6":
                return this.$t("TXT_SUM_CATE1_2_3");
              case "1 & 2 & 3":
                return this.$t("TXT_SUM_CATE1_2_3");
              default:
                break;
            }
          } else if (type === 1) {
            switch (scope) {
              case "1":
                return this.$t("TXT_SCOPE_1");
              case "2":
                return this.$t("TXT_SCOPE_2");
              case "3":
                return this.$t("TXT_SCOPE_3");
              case "อื่นๆ":
                return this.$t("TXT_SCOPE_ETC");
              case "1 & 2":
                return this.$t("TXT_SUM_SCOPE1_2");
              case "1 & 2 & 3":
                return this.$t("TXT_SUM_SCOPE1_2_3");
              default:
                break;
            }
          }
        }
      } else if (type === 2) {
        return this.$t("TXT_ISO_CATE");
      } else if (type === 1) {
        return this.$t("TXT_SCOPE");
      }
    },
    checkFirstPathPermission() {
      const object_permission = store.state.permissions;
    
      // สร้าง Array ของสิทธิ์การเข้าถึง
      const array_access = [
        object_permission.application?.access,
        object_permission.assign_role?.access,
        object_permission.assign_task?.access,
        object_permission.audit?.access,
        object_permission.custom_ef?.access,
        object_permission.filling_out?.access,
        object_permission.goal?.access,
        object_permission.measure_list?.access,
        object_permission.organization_profile?.access,
        object_permission.organization_structure?.access,
        object_permission.overview?.access,
        object_permission.setting?.access,
        object_permission.transaction_log?.access,
        object_permission.user_account?.access,
      ];
    
      // นับจำนวนสิทธิ์ที่เป็น `true`
      const trueCount = array_access.filter(Boolean).length;
    
      // กำหนดเส้นทาง Redirect ตามสิทธิ์
      const redirectMapping = [
        { condition: object_permission.overview?.access, path: "/overview-dashboard" },
        { condition: object_permission.organization_structure?.access, path: "/organization-setting/organization" },
        { condition: object_permission.measure_list?.access, path: "/manage-activities" },
        { condition: object_permission.user_account?.access, path: "/manage-users" },
        { condition: object_permission.filling_out?.access, path: "/measurement-list" },
        { condition: object_permission.custom_ef?.access, path: "/mapping-emission-factor" },
        { condition: object_permission.application?.access, path: "/approval" },
        { condition: object_permission.audit?.access, path: "/verify-information" },
      ];
    
      // Logic สำหรับการ Redirect
      if (trueCount > 1) {
        for (const { condition, path } of redirectMapping) {
          if (condition) {
            return path;
          }
        }
      } else if (trueCount === 1) {
        const singleAccess = redirectMapping.find(({ condition }) => condition);
        if (singleAccess) {
          return singleAccess.path;
        }
      }
    },
    redirectOnLogout() {
      if (
        this.$store.state.user_data.type == 2 ||
        this.$store.state.user_data.type == 3
      ) {
        //auditor
        this.$router.push("/login-auditor");
      } else {
        this.$router.push("/");
      }
    },
    dateTimeNow(type) {
      const lang_date = lang == "th" ? "th" : "en";
      moment.locale(lang_date);
      const formats = {
        1: "YYYY-MM-DD HH:mm:ss",
        2: "YYYY-MM-DD",
        3: "HH:mm:ss"
      };
      return moment().format(formats[type] || formats[1]);
    },
    // วันที่ปัจจุบัน format 28 ม.ค. 2568 ตามภาษา
    getFormattedDateNow() {
        const now = new Date();
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        let formattedDate;
    
        if (lang === 'th') {
            options.locale = 'th-TH';
            options.year = 'numeric';
            formattedDate = now.toLocaleDateString('th-TH', options);
        } else {
            options.locale = 'en-US';
            formattedDate = now.toLocaleDateString('en-US', options);
        }
    
        return formattedDate.replace(',', ''); // ลบเครื่องหมายจุลภาคออก (สำหรับภาษาอังกฤษ)
    },
    // เวลาปัจจุบัน format 2.30 p.m ตามภาษา
    getFormatTimeNow() {
      const now = new Date();
        const options = { hour: 'numeric', minute: 'numeric', hour12: lang === 'en' };
      
        if (lang === 'th') {
          return now.toLocaleTimeString('th-TH', options).replace('.', ':') + ' น.';
        } else {
          return now.toLocaleTimeString('en-US', options).toLowerCase();
        }
    },
    // 23 กรกฎาคม 2567 เวลา 14:30 น. or 23 July 2024 at 2:30 p.m.
    formatDateWithLang(isoString) {
      const date = new Date(isoString);
  
      // แปลงเวลาไปยังโซนเวลาไทย (UTC+7)
      const thailandOffset = 7 * 60 * 60 * 1000; // 7 ชั่วโมงเป็นมิลลิวินาที
      const localDate = new Date(date.getTime() + thailandOffset);
  
      // ดึงค่า วัน, เดือน, ปี
      const day = localDate.getDate();
      const monthTh = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
      const monthEn = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      
      if (lang === "th") {
          const buddhistYear = localDate.getFullYear() + 543;
          return `${day} ${monthTh[localDate.getMonth()]} ${buddhistYear}`;
      } else {
          const christianYear = localDate.getFullYear();
          return `${day} ${monthEn[localDate.getMonth()]} ${christianYear}`;
      }
    },
    formatTimeWithLang(isoString) {
      if (!isoString) return "";

      const time = moment(isoString).utcOffset(7); // ปรับเป็นโซนเวลาไทย (UTC+7)
        return lang === "th"
          ? time.locale("th").format("เวลา HH.mm น.")
          : `at ${time.format("h:mm")} ${this.getAmPm(time)}`;
      
      // const date = new Date(isoString);
  
      // // แปลงเวลาไปยังโซนเวลาไทย (UTC+7)
      // const thailandOffset = 7 * 60 * 60 * 1000; // 7 ชั่วโมงเป็นมิลลิวินาที
      // const localDate = new Date(date.getTime() + thailandOffset);
      // const hours24 = localDate.getHours();
      // const minutes = localDate.getMinutes().toString().padStart(2, "0");
  
      // if (lang === "th") {
      //     return `เวลา ${hours24}.${minutes} น.`;
      // } else {
      //     const hours12 = hours24 % 12 || 12; // แปลง 24 ชั่วโมงเป็น 12 ชั่วโมง
      //     const period = hours24 < 12 ? "a.m." : "p.m.";
      //     return `at ${hours12}:${minutes} ${period}`;
      // }
    },
    getAmPm(time) {
      return moment(time).format("A") === "AM" ? "a.m." : "p.m.";
    },
  },
};
