import axios from "axios"
import { apiURL } from "@/configs/serverConfig";
import store from "@/store";

async function uploadFiles(formData, filePath, isMulti = false) {
    const url = new URL(`${apiURL}/${isMulti ? 'file/uploads' : 'file'}`);

    if (filePath) {
        url.searchParams.append('file-path', filePath);
    }

    const headers = {
        Accept: "application/json",
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${store.state.token}`,
    };

    try {
        const response = await axios.post(url.toString(), formData, { headers });
        return response;
    } catch (err) {
        console.error("Error during file upload:", err.response?.data?.message || err.message);  // Log more detailed error
        throw err; // Rethrow to propagate the error
    }
}
  
// ใช้งาน
export async function uploadFileGlobal(formData, filePath) {
    return uploadFiles(formData, filePath);
}

export async function uploadMultiFileGlobal(formData, filePath) {
    return uploadFiles(formData, filePath, true);
}
  