export default {
  methods: {
    loadFreshdeskWidget() {
      this.setFreshdeskSettings();
      this.loadScript(
        "https://widget.freshworks.com/widgets/157000000440.js",
        () => {
          this.observeFreshdeskWidget(); // เรียกใช้ observer หลังจากโหลด script
        }
      );
    },

    // Helper function to set Freshdesk widget settings
    setFreshdeskSettings() {
      window.fwSettings = { widget_id: 157000000440 };
      if (typeof window.FreshworksWidget !== "function") {
        window.FreshworksWidget = function () {
          window.FreshworksWidget.q.push(arguments);
        };
        window.FreshworksWidget.q = [];
      }
    },

    // Helper function to load a script dynamically
    loadScript(src, callback) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = src;
      script.async = true;
      script.defer = true;
      script.onload = callback; // เรียก callback เมื่อ script โหลดเสร็จ
      document.body.appendChild(script);
    },

    // ใช้ MutationObserver เพื่อตรวจจับการสร้าง freshworks-container
    observeFreshdeskWidget() {
      const observer = new MutationObserver(() => {
        const widgetElement = document.querySelector("#freshworks-container");
        const appElement = document.querySelector("#app");

        if (widgetElement && appElement) {
          appElement.appendChild(widgetElement); // ย้ายเข้าไปใน #app
          observer.disconnect(); // หยุดเฝ้าดูเมื่อเจอ element แล้ว
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },

    hideFreshdeskWidget() {
      if (typeof window.FreshworksWidget === "function") {
        window.FreshworksWidget("close"); // Hide the widget
      }
      const widgetElement = document.querySelector("#freshworks-container");
      if (widgetElement) {
        widgetElement.style.display = "none";
      }
    },
  },
};
