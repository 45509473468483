import Cookies from 'js-cookie';
import { httpClient } from "@/services/axiosInstance.js";
import { apiURL } from "../configs/serverConfig";

export async function getUserGlobal() {
  const header = {
    Accept: "application/json",
    "Content-type": "application/json",
    SessionID: Cookies.get("session"),
  };

  try {
    const user = await httpClient.get(`${apiURL}/user`, { headers: header });
    return user.data;
  } catch (err) {
    console.error("Error fetching user data:", err);  // Log the error
    throw err;
  }
}

export async function getMemberGlobal() {
    try {
      const response = await httpClient.get(`${apiURL}/member`);
      return response.data;
    } catch (err) {
        console.error("Error fetching member data:", err);  // Log the error
        throw err;
    }
}
