import { getUserGlobal, getMemberGlobal } from '@/utils/userApi';
import { getCompanyGlobal, getBranchGlobal } from '@/utils/companyApi';
import { uploadFileGlobal, uploadMultiFileGlobal } from '@/utils/fileApi';

export default {
  install(Vue) {
    Vue.prototype.$getUserGlobal = getUserGlobal;
    Vue.prototype.$getMemberGlobal = getMemberGlobal;
    Vue.prototype.$getCompanyGlobal = getCompanyGlobal;
    Vue.prototype.$getBranchGlobal = getBranchGlobal;
    Vue.prototype.$uploadFileGlobal = uploadFileGlobal;
    Vue.prototype.$uploadMultiFileGlobal = uploadMultiFileGlobal;
  }
};