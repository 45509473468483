import i18n from '@/js/i18n.js';

export default {
    namespaced: true,
    state: {
        // ที่มาของหลักฐาน
        dropdownSourceEvidence:  [],
    },
    mutations: {
        setDropdownSourceEvidence(state, dropdown) {
            state.dropdownSourceEvidence = dropdown;
        },
    },
    actions: {
        setDropdownSourceEvidence({ commit }) {
            const dropdown = [
                { id : 0, name : i18n.t('TXT_SOURCE_EVIDENCE_NOT_ADD')},
                { id : 1, name : i18n.t('DD_FROM_ESTIMATION')},
                { id : 2, name : i18n.t('DD_FROM_PROOF_OF_PAYMENT')},
                { id : 3, name : i18n.t('DD_FROM_MEASUREMENT_LIST')}
            ]
            commit('setDropdownSourceEvidence', dropdown);
        },
    },
    getters: {
    },
}