import i18n from '@/js/i18n.js';

export default {
    namespaced: true,
    state: {
        auditorTab: [
            { id: 1, name: "LB_ACTIVITY_LIST", url: "/auditor-activity" },
            { id: 2, name: "LB_EMISSION_FACTOR", url: "/auditor-emission-factor" },
            { id: 3, name: "TXT_ORGANIZATIONAL_DIVSION", url: "/auditor-orgnizaion-fractionation" }
        ],
        manageOrganizationTab : []
    },
    mutations: {
        setTabManageOrganizationTab(state,tab) {
            state.manageOrganizationTab = tab;
        }
    },
    actions: {
        setTabManageOrganizationTab({ commit }) {
            const organizationTab = [
                { id: 1, name: i18n.t("TXT_ORGANIZATION_STRUCTURE"), url : "organization" },
                { id: 2, name: i18n.t("BC_BRANCH_STRUCTURE"), url : "branch" },
                { id: 3, name: i18n.t("TXT_BUILDING_STRUCTURE"), url : "building" },
                // { id: 4, name: i18n.t("TXT_DEPARTMENT_STRUCTURE"), url : "department" },
                { id: 5, name: i18n.t("LB_SET_FISCAL_YEAR"), url : "setting-fiscal-year" },
            ];

            commit('setTabManageOrganizationTab', organizationTab);
        }
    },
    getters: {
        auditorTab: (state) => state.auditorTab,
        organizationTab : (state) => state.manageOrganizationTab
    },
}