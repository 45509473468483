import { httpClient } from "@/services/axiosInstance.js";
import { apiURL } from "../configs/serverConfig";

export async function getCompanyGlobal(params) {
    try {
      const company = await httpClient.get(`${apiURL}/company`, { params: params });
      return company.data;
    } catch (err) {
      console.error("Error fetching user data:", err);  // Log the error
      throw err;
    }
}

export async function getBranchGlobal(params) {
  try {
    const branch = await httpClient.get(`${apiURL}/branch`, { params: params });
    return branch.data;
  } catch (err) {
    console.error("Error fetching user data:", err);  // Log the error
    throw err;
  }
}