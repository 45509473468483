<template>
  <div id="app">
    <AppLayout>
      <router-view />
    </AppLayout>
    <ModalSession
      size="md"
      id="session-expired"
      :title="$t('TXT_SESSION_EXPIRED')"
      :content="$t('TXT_SESSION_EXPIRED_CONTENT', { time: formattedTime })"
    />
  </div>
</template>

<script>
import { startIdleTimer, stopIdleTimer } from "../src/utils/idleTimer";
import { mapActions } from "vuex";

import AppLayout from "@/layouts/AppLayout";
import ModalSession from "@/components/Modal/Alert/ModalSession.vue";
import { httpClient } from "@/services/axiosInstance";
import { apiURL } from "@/configs/serverConfig";

export default {
  name: "App",
  components: {
    AppLayout,
    ModalSession,
  },
  data() {
    return {
      layout: "div",
      refreshing: false,
      worker: null,
      updateExists: false,
      sessionTimeout: 300, // 5 minutes in seconds
      countdownInterval: null, // To store the countdown interval
      sessionTimeoutTimer: null, // To store the session timeout timer
      isModalVisible: false,
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.sessionTimeout / 60);
      const seconds = this.sessionTimeout % 60;
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
        2,
        "0"
      )}`;
    },
  },
  mounted() {
    // console.log("idleTime", process.env.VUE_APP_IDLE_TIMEOUT);
    startIdleTimer(() => {
      if (this.$route.name === "login" || this.$route.name === "login-auditor")
        return;
      this.$bvModal.show("session-expired"); // Show session expired modal
      // this.startSessionTimeout(); // Start session timeout
      this.startCountdown(); // Start countdown for session timeout
    });
    // Add mousemove event listener
    window.addEventListener("mousemove", this.handleMouseMove);
  },

  beforeDestroy() {
    stopIdleTimer();
    if (this.countdownInterval) clearInterval(this.countdownInterval);
    // Remove event listeners when the component is destroyed
    window.removeEventListener("mousemove", this.handleMouseMove);
  },

  methods: {
    ...mapActions(["removeSession"]),

    startCountdown() {
      // บันทึกเวลาเริ่มต้น
      this.sessionTimeout = 300;
      const startTime = Date.now();

      // ล้าง Interval เดิมถ้ามี
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }

      // สร้าง Interval ใหม่
      this.countdownInterval = setInterval(() => {
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000); // เวลาที่ผ่านไป
        this.sessionTimeout = 300 - elapsedTime; // คำนวณเวลาที่เหลือ

        if (this.sessionTimeout <= 0) {
          clearInterval(this.countdownInterval); // หยุด Interval เมื่อหมดเวลา
          // this.sessionTimeout = 0;
          this.startSessionTimeout();
          // this.sessionExpired(); // เรียกฟังก์ชันเมื่อหมดเวลา
        }
      }, 1000); // อัปเดตทุก 1 วินาที
    },

    async startSessionTimeout() {
      const url = `${apiURL}/user/logout`;

      try {
        const logout = await httpClient.post(url);
        if (logout.status == 200) {
          await this.removeSession();
          this.$router.push({ name: "login" }).then(() => {
            if (typeof window.FreshworksWidget === "function") {
              window.FreshworksWidget("hide"); // Hide the widget
            }
          });
          this.$bvModal.hide("session-expired");
        }
      } catch (err) {
        if (err) {
          this.$toast.error({
            component: "styled-toast",
            props: {
              type: "error",
              message: err.response.data.message,
            },
          });
        }
      }
    },

    handleMouseMove() {
      clearInterval(this.countdownInterval);
      this.$bvModal.hide("session-expired"); // Hide session expired modal
    },
  },
};
</script>

<style lang="scss">
#app {
  color: #2c3e50;
  background: var(--tertiary-grey_10);
}
</style>
